import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M10.8 3.3a7.5 7.5 0 0 1 5.642 12.442l.268.258h.79l4.24 4.26c.41.41.41 1.08 0 1.49a1.06 1.06 0 0 1-1.391.087l-.099-.087L16 17.5v-.79l-.258-.268A7.5 7.5 0 1 1 10.8 3.3m0 2a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11"
    }, null, -1)
  ])))
}
export default { render: render }